import {useAppUtils} from "@/composables/useAppUtils"
import {INoteFormDraft} from "@/types"
import {useBrowserStorage} from "@/composables/useBrowserStorage"

export function useNoteDraft () {

  const {
    getAuthUser,
  } = useAppUtils()

  const {
    getBrowserStorageItem,
    setBrowserStorageItem,
    removeBrowserStorageItem,
  } = useBrowserStorage()

  const draftStorageType = 'localStorage'

  const getDraftStorageKey = (formType: 'top' | 'bottom'): string => 'note_form_' + formType

  const getNoteFormDraft = (formType: 'top' | 'bottom'): INoteFormDraft | null => {
    let draft = null
    const draftStorageKey = getDraftStorageKey(formType)
    if (draftStorageKey) {
      const formDraftStorageValue = getBrowserStorageItem(draftStorageKey, draftStorageType)
      if (formDraftStorageValue) {
        try {
          draft = JSON.parse(formDraftStorageValue)
        } catch (e) {
          console.log('useAppUtils getNoteFormDraft error', e)
        }
      }
    }
    return draft
  }

  const isNoteDraftForTicket = (formDraft: INoteFormDraft | null, ticketId?: string) => formDraft && ticketId && formDraft.note && formDraft.ticket_id === ticketId
  const isNoteDraftForTicketTopForm = (formDraft: INoteFormDraft | null, ticketId?: string) => formDraft && isNoteDraftForTicket(formDraft, ticketId) && !formDraft.reply && !formDraft.id
  const isNoteDraftForNoteReply = (formDraft: INoteFormDraft | null, noteId?: string) => formDraft && noteId && formDraft.note && formDraft.reply === noteId
  const isNoteDraftForNoteEdit = (formDraft: INoteFormDraft | null, noteId?: string) => formDraft && noteId && formDraft.note && formDraft.id === noteId

  const saveNoteDraftToStorage = (formType: 'top' | 'bottom', data: INoteFormDraft) => {
    setBrowserStorageItem(getDraftStorageKey(formType), JSON.stringify(data), draftStorageType)
  }

  const removeNoteDraftFromStorage = (formType: 'top' | 'bottom') => {
    removeBrowserStorageItem(getDraftStorageKey(formType), draftStorageType)
  }

  return {
    getNoteFormDraft,
    isNoteDraftForTicket,
    isNoteDraftForTicketTopForm,
    isNoteDraftForNoteReply,
    isNoteDraftForNoteEdit,
    saveNoteDraftToStorage,
    removeNoteDraftFromStorage,
  }

}
