import _uniqueId from "lodash/uniqueId"
import {ref} from "vue"

const dropObserverItems = new Map()

let dragLeaveTimeout
let filesDraggingTimeout

const filesDraggingActive = ref(false)
const dragdropHandler = (event) => {
  let dropEffect = 'none'


  if (event.dataTransfer && event.dataTransfer.types.includes('Files')) {
    console.log('dragdropHandler dragging', event.type, event)
    event.stopPropagation()
    event.preventDefault()

    const isEnterEvent = event.type === 'dragenter' || event.type === 'dragover'
    const isLeaveEvent = event.type === 'dragleave' || event.type === 'drop'

    if (isEnterEvent) {
      clearTimeout(filesDraggingTimeout)
      console.log('dragdropHandler filesDraggingActive', true)
      filesDraggingActive.value = true
    }

    if (isLeaveEvent) {
      clearTimeout(filesDraggingTimeout)
      filesDraggingTimeout = setTimeout(() => {
        console.log('dragdropHandler filesDraggingActive', false)
        filesDraggingActive.value = false
      }, 100)
    }

    dropObserverItems.forEach(item => {
      if (item?.dropElementRef?.value && item.dropElementRef.value.contains(event.target)) {
        dropEffect = 'copy'
        //item.callback()

        if (isEnterEvent) {
          clearTimeout(dragLeaveTimeout)
          if (typeof item.callbacks.onDragEnter === 'function') {
            console.log('dragdropHandler onDragEnter', event, item, dropObserverItems.size, dropObserverItems)
            item.callbacks.onDragEnter(item, event)
          }
        }

        if (isLeaveEvent && typeof item.callbacks.onDragLeave === 'function') {
          clearTimeout(dragLeaveTimeout)
          dragLeaveTimeout =  setTimeout(() => {
            if (item && typeof item.callbacks?.onDragLeave === 'function') {
              console.log('dragdropHandler onDragLeave', event, item, dropObserverItems.size, dropObserverItems)
              item.callbacks.onDragLeave(item, event)
            }
          }, 100)
        }
      }
    })


  }
  event.dataTransfer.dropEffect = dropEffect

}

document.addEventListener('dragover', dragdropHandler)
document.addEventListener('dragenter', dragdropHandler)
document.addEventListener('dragleave', dragdropHandler)
document.addEventListener('drop', dragdropHandler)

const addDropObserverItem = (dropElementRef, callbacks = {}) => {
  const uid = _uniqueId('dropObserverItem')
  if (dropElementRef) {
    dropObserverItems.set(uid, {
      uid,
      dropElementRef,
      callbacks,
    })
  }
  return uid
}

const removeDropObserverItem = (uid) => {
  if (uid) {
    dropObserverItems.delete(uid)
    //resizeObserver.unobserve(target)
  }
}

export function useFilesDrag() {

  return {
    filesDraggingActive,
    addDropObserverItem,
    removeDropObserverItem,
  }

}
