import GlobalService from "@/services/global_service";
import {computed, ref, watch} from "vue"
import {useStore} from "vuex"
//import _throttle from 'lodash/throttle'

export function useResizePanels (refScreenPanelResizable, /*resizablePanel: 'left' | 'right',*/ screenName, options?) {

  // let resizePanelsStartX = 0
  // const resizePanelsStartHandler = (event) => {
  //   console.log('resizePanelsStartHandler', event.originalEvent)
  //   resizePanelsStartX = event.originalEvent.pageX
  // }
  // const resizePanelsHandler = (event) => {
  //   console.log('resizePanelsHandler', event.originalEvent)
  //   const leftPanelElement = document.querySelector('.screen-panel-left')
  //   if (leftPanelElement) {
  //     leftPanelElement['style']['width'] = (parseFloat(leftPanelElement['style']['width']) + (event.originalEvent.pageX - resizePanelsStartX)) + 'px'
  //   }
  // }

  const store = useStore()

  const resizeOnMoveRef = ref<boolean>(options.resizeOnMove)
  const isSpaceToMove = ref(true)

  const resizablePanel = computed(() => store.getters[screenName + '/resizablePanel'])
  const storeResizablePanelWidth = computed(() => store.getters[screenName + '/resizablePanelWidth'])
  const leftPanelMinWidth = computed(() => store.getters[screenName + '/leftPanelMinWidth'])
  const rightPanelMinWidth = computed(() => store.getters[screenName + '/rightPanelMinWidth'])

  let _contentElement: HTMLDivElement | null = null
  const getContentElement = () => {
    if (!_contentElement) {
      _contentElement = document.querySelector('.screen-container')
    }
    return _contentElement
  }

  const getLeftPanelWidth = (dividerOffset) => {
    //console.log('useResizePanels getLeftPanelWidth', dividerOffset, leftPanelMinWidth.value, rightPanelMinWidth.value)
    const contentElement: HTMLDivElement | null = getContentElement()
    let leftPanelWidth = dividerOffset
    isSpaceToMove.value = true
    if (contentElement) {
      leftPanelWidth = Math.min(Math.max(dividerOffset, leftPanelMinWidth.value), contentElement.offsetWidth - rightPanelMinWidth.value)
      if (leftPanelWidth < leftPanelMinWidth.value) {
        leftPanelWidth = Math.round(contentElement.offsetWidth / 2)
        isSpaceToMove.value = false
      }
    }
    return leftPanelWidth
  }

  const getRightPanelWidth = (dividerOffset, availableSpace?) => {
    //console.log('useResizePanels getRightPanelWidth', dividerOffset, leftPanelMinWidth.value, rightPanelMinWidth.value)
    const contentElement: HTMLDivElement | null = getContentElement()
    let rightPanelWidth = dividerOffset
    isSpaceToMove.value = true
    if (contentElement) {
      availableSpace = availableSpace || contentElement.offsetWidth
      rightPanelWidth = Math.min(Math.max(dividerOffset, rightPanelMinWidth.value), availableSpace - leftPanelMinWidth.value)
      if (rightPanelWidth < rightPanelMinWidth.value) {
        rightPanelWidth = Math.round(contentElement.offsetWidth / 2)
        isSpaceToMove.value = false
      }
    }
    return rightPanelWidth
  }

  const getResizablePanelWidth = (dividerOffset, availableSpace?) => {
    return resizablePanel.value === 'left' ? getLeftPanelWidth(dividerOffset) : getRightPanelWidth(dividerOffset, availableSpace)
  }

  const getResizablePanelMinWidth = () => {
    return resizablePanel.value === 'left' ? leftPanelMinWidth.value : rightPanelMinWidth.value
  }

  const resizablePanelWidth = ref()

  watch(() => storeResizablePanelWidth.value, value => {
    //console.log('watch props.left storeResizablePanelWidth', value)
    resizablePanelWidth.value = getResizablePanelWidth(value)
  }, {immediate: true})

  //const leftPanelWidth = ref(storeLeftPanelWidth.value)

  // const leftPanelWidth = computed(() => {
  //   const contentElement: HTMLDivElement | null = getContentElement()
  //   return contentElement ? Math.min(Math.max(storeLeftPanelWidth.value, leftPanelMinWidth.value), contentElement.offsetWidth - rightPanelMinWidth.value) : storeLeftPanelWidth.value
  // })

  // const panelsDividerMoveHandler = _throttle(value => {
  //   if (resizeOnMoveRef.value && refScreenPanelResizable.value) {
  //     //const width = getResizablePanelWidth(value)
  //     refScreenPanelResizable.value.style.width = `${value}px`
  //     console.log('useResizePanels getRightPanelWidth panelsDividerMoveHandler', value, performance.now())
  //   }
  // }, 100)

  const panelsDividerMoveHandler = value => {
    if (resizeOnMoveRef.value && refScreenPanelResizable.value) {
      //const width = getResizablePanelWidth(value)
      refScreenPanelResizable.value.style.width = `${value}px`
      //console.log('useResizePanels getRightPanelWidth panelsDividerMoveHandler', value, performance.now())
    }
  }

  const panelsDividerMoveEndHandler = async (value) => {
    resizablePanelWidth.value = getResizablePanelWidth(value)
    //console.log('panelsDividerMoveEndHandler', screenName, resizablePanel.value, resizablePanelWidth.value, value, getResizablePanelMinWidth())
    if (resizablePanelWidth.value >= getResizablePanelMinWidth()) {
      store.commit(screenName + '/setResizablePanelWidth', resizablePanelWidth.value)
      const settingName = screenName + '_width'
      try {
        await GlobalService.setSetting(settingName, resizablePanelWidth.value, settingName, {silent: true})
      } catch (e) {
        console.log('useResizePanels panelsDividerMoveEndHandler setSetting error', e)
      }
    }
  }


  return {
    // resizePanelsStartHandler,
    // resizePanelsHandler,
    resizablePanel,
    resizeOnMoveRef,
    isSpaceToMove,
    resizablePanelWidth,
    leftPanelMinWidth,
    rightPanelMinWidth,
    panelsDividerMoveHandler,
    panelsDividerMoveEndHandler,
    getResizablePanelWidth,
  }
}
