import {IApiResponse, IData, ITicket} from "@/types"
import ApiService from "@/services/api_service"
import qs from "qs"
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';

export default {

  multiRemove(ids: string[], cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'multi_remove'

      const params: IData = {
        action: actionName,
        tickets: ids,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketsMultieditService multiRemove success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketsMultieditService multiRemove error', data)
        reject(data)
      })
    })

  },

  multiClone(ids: string[], cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'multi_clone'

      const params: IData = {
        action: actionName,
        tickets: ids,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketsMultieditService multiClone success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketsMultieditService multiClone error', data)
        reject(data)
      })
    })

  },

  multiArchive(ids: string[], archive: 0 | 1, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'multi_archive'

      const params: IData = {
        action: actionName,
        tickets: ids,
        archive,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketsMultieditService multiArchive success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketsMultieditService multiArchive error', data)
        reject(data)
      })
    })

  },

  multiApply(ids: string[], attrs: IData, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'multi_edit'

      const params: IData = Object.assign({
        action: actionName,
        tickets: ids,
      }, attrs)

      console.log('TicketsMultieditService multiApply params', params, JSON.stringify(params))

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketsMultieditService multiApply success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketsMultieditService multiApply error', data)
        reject(data)
      })
    })

  },

}
