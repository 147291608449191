import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19fba168"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "data-paging-container"
}
const _hoisted_2 = { class: "paginator" }
const _hoisted_3 = {
  key: 0,
  class: "paginator-page"
}
const _hoisted_4 = {
  key: 1,
  class: "paginator-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.itemsLeft.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.t('page:')) + " ", 1),
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsLeft, (pageLeft) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["paginator-page", {'paginator-page-current': pageLeft === _ctx.page}]),
              key: pageLeft
            }, [
              _renderSlot(_ctx.$slots, "pageItem", { pageNumber: pageLeft }, () => [
                _createTextVNode(_toDisplayString(pageLeft), 1)
              ], true)
            ], 2))
          }), 128)),
          (_ctx.itemsMiddle.length)
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, "..."))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsMiddle, (pageMiddle) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["paginator-page", {'paginator-page-current': pageMiddle === _ctx.page}]),
              key: pageMiddle
            }, [
              _renderSlot(_ctx.$slots, "pageItem", { pageNumber: pageMiddle }, () => [
                _createTextVNode(_toDisplayString(pageMiddle), 1)
              ], true)
            ], 2))
          }), 128)),
          (_ctx.itemsRight.length)
            ? (_openBlock(), _createElementBlock("li", _hoisted_4, "..."))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsRight, (pageRight) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["paginator-page", {'paginator-page-current': pageRight === _ctx.page}]),
              key: pageRight
            }, [
              _renderSlot(_ctx.$slots, "pageItem", { pageNumber: pageRight }, () => [
                _createTextVNode(_toDisplayString(pageRight), 1)
              ], true)
            ], 2))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}