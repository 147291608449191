import {IApiResponse, IData, ITicketTask} from "@/types"
import ApiService from "@/services/api_service"
import qs from "qs"

export default {
  getTasks(ticketId: string, hideClosed: boolean, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'get_ticket_todos'

      const params: IData = {
        action: actionName,
        ticket_id: ticketId,
        hide_closed_todo: hideClosed,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketTasksService getTasks success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketTasksService getTasks error', data)
        reject(data)
      })
    })

  },
  saveTask(task: Partial<ITicketTask>, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'save_todos'

      const params: IData = {
        action: actionName,
        ticket_id: task.ticket_id,
        text: task.text,
        done: task.is_active ? 0 : 1,
      }

      if (task.id) {
        params.todo_id = task.id
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketTasksService saveTask success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketTasksService saveTask error', data)
        reject(data)
      })
    })

  },

  removeTask(task: Partial<ITicketTask> | {id: 'all', ticket_id: string}, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'remove_todo'

      if (!task.id) {
        reject({})
        return
      }

      const params: IData = {
        action: actionName,
        ticket_id: task.ticket_id,
        todo_id: task.id,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketTasksService removeTask success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketTasksService removeTask error', data)
        reject(data)
      })
    })

  },

  moveTask(task: ITicketTask, prevTaskId: number | string | null, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'move_todo'

      const params: IData = {
        action: actionName,
        ticket_id: task.ticket_id,
        todo_id: task.id,
        prev_todo: prevTaskId,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketTasksService moveTask success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketTasksService moveTask error', data)
        reject(data)
      })
    })

  },

  toggleTasksExpanded(ticketId: string, isExpanded: boolean, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'toggle_tasks_expanded'

      const params: IData = {
        action: actionName,
        id: ticketId,
        status: isExpanded ? 1 : 0,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketTasksService toggleTasksExpanded success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketTasksService toggleTasksExpanded error', data)
        reject(data)
      })
    })
  },


}
