
import {computed, defineComponent, ref, watch, nextTick, onMounted, toRefs, toRef} from "vue"
import {useI18n} from "vue-i18n"
import {IData, ITicketTag} from "@/types"
import {useStore} from "vuex"
import {useFetchState} from "@/composables/useFetchState"
import SvgIcon from "@/components/SvgIcon"
import SeekerUsers from '@/components/Seekers/SeekerUsers.vue'
import SeekerTags from '@/components/Seekers/SeekerTags.vue'
import SeekerProjects from '@/components/Seekers/SeekerProjects.vue'
import SearchExtraOptions from "@/components/SearchExtraOptions/SearchExtraOptions.vue";
import TheCustomFieldsSelector from "@/components/TheCustomFieldsSelector/TheCustomFieldsSelector.vue";
import {useFloatingExists} from '@/composables/useFloatingExists'
import UserAvatar from '@/components/UserAvatar'
import {useAppUtils} from "@/composables/useAppUtils"
import {useBrowserStorage} from "@/composables/useBrowserStorage"
import _isEqual from 'lodash/isEqual'
import {useRouter} from "vue-router"

// import VMenu from '@/components/Menus/VMenu.vue'
// import VMenuItem from '@/components/Menus/VMenuItem.vue'
// import VMenuSeparator from '@/components/Menus/VMenuSeparator.vue'

export default defineComponent({
  name: 'TicketsToolbar',
  emits: [
    'menuTrigger',
  ],
  components: {
    TheCustomFieldsSelector,
    SearchExtraOptions,
    SvgIcon,
    SeekerUsers,
    SeekerTags,
    SeekerProjects,
    UserAvatar,
    // VMenu,
    // VMenuItem,
    // VMenuSeparator,
  },
  props: {
    screen: {
      type: String,
      // validator(value: string) {
      //   // Значение должно соответствовать одной из этих строк
      //   return ['board', 'dashboard', 'listview'].includes(value)
      // },
      default: 'board',
      required: true,
    },
    canAddTicket: {
      type: Boolean,
      default: true,
    }
  },

  setup(props, {emit}) { //props, context

    console.log('TicketsToolbar setup', props)

    const {screen} = toRefs(props);

    //const moduleName = screen.value;

    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    const searchExtraPopup = ref();
    const refSearchExtraTo = ref();
    const customFieldsPopup = ref();
    const refCustomFieldsTo = ref();
    const refSearchTextInput = ref();
    const refAddTicketButton = ref()
    const refSeekerProjects = ref<InstanceType<typeof SeekerProjects> | null>(null)


    const projectID = computed(() => store.getters.project?.id)

    // const getSearchExtraTriggerTargets = function () {
    //   return [refSearchExtraTo];
    // }

    const {isExist: isExistSearchExtraPopup, triggerHandler: triggerHandlerSearchExtraPopup} = useFloatingExists(searchExtraPopup)
    const {isExist: isExistCustomFieldsPopup, triggerHandler: triggerHandlerCustomFieldsPopup} = useFloatingExists(customFieldsPopup)
    const {isExist: isExistSeekerProjects, triggerHandler: triggerHandlerSeekerProjects} = useFloatingExists(refSeekerProjects)

    const {
      isMayContinue,
      showPanelsLoaders,
      hidePanelsLoaders,
    } = useAppUtils()

    // const refMenu = ref<InstanceType<typeof VMenu> | null>(null)
    // const {isExist: isExistMenu, triggerHandler: triggerHandlerMenu} = useFloatingExists(refMenu)

    // const openSearchPopup = async () => {
    //   //searchExtraPopup.value.isOpened = !searchExtraPopup.value.isOpened;
    //   await triggerHandlerSearchExtraPopup()
    // }

    // const openCustomFieldsPopup = async () => {
    //   //customFieldsPopup.value.isOpened = !customFieldsPopup.value.isOpened;
    //   await triggerHandlerCustomFieldsPopup()
    // }

    // const namespace = ref(screen.value);

    const textFilter = computed(() => store.getters['ticketsfilters/search'])
    const searchFields = computed(() => store.getters['ticketsfilters/searchFields'])
    const searchIn = computed(() => store.getters['ticketsfilters/searchIn'])
    const searchInDefault = computed(() => store.getters['ticketsfilters/searchInDefault'])

    // const selectedSearchFieldsNames = computed(() => searchFields.value.filter(item => item.checked).map(item => item.key))
    const requestedByFilter = computed(() => store.getters['ticketsfilters/requestedById'])
    const assignedToFilter = computed(() => store.getters['ticketsfilters/assignedToId'])
    //const tagsFilterValues = computed(() => store.getters['tagsFilterValues'])
    const users = computed(() => store.getters['usersForFilter'])

    const a = {};
    a['board'] = useFetchState({storeNamespace: 'board'});
    a['listview'] = useFetchState({storeNamespace: 'listview'});
    a['dashboard'] = useFetchState({storeNamespace: 'dashboard'});

    let changeStateParams
    let fetchState
    // let updateLocationHref
    // let getRouteObj

    watch(screen, ()=>{
      changeStateParams = a[screen.value].changeStateParams
      fetchState = a[screen.value].fetchState
      // updateLocationHref = a[screen.value].updateLocationHref
      // getRouteObj = a[screen.value].getRouteObj
    }, {immediate: true});

    // const {changeStateParams} = useFetchState({
    //   storeNamespace: moduleName,
    // })

    const {
      getBrowserStorageItem,
      setBrowserStorageItem,
      removeBrowserStorageItem,
      getSearchInStorageKey,
      getSearchInStorageType,
      getSearchInFromStorage,
    } = useBrowserStorage()

    // const searchInStorageType = 'localStorage'
    // const getSearchInStorageKey = (): string => 'tickets_search_in.p_' + projectID.value


    let timer

    const updateTextFilter = async (event) => {
      if (event.currentTarget.hasAttribute('action')) {
        if (! await isMayContinue()) {
          return
        }
        showPanelsLoaders()
        const result = await changeStateParams({search: '', selectedItemId: null})
        console.log('TicketsToolbar updateTextFilter result', result)
        if (result !== 'cancel') {
          await hidePanelsLoaders()
        }
      } else {
        clearTimeout(timer)
        timer = setTimeout(async () => {
          if (! await isMayContinue()) {
            return
          }
          showPanelsLoaders()
          const result = await changeStateParams({search: event.target.value, searchIn: searchIn.value, selectedItemId: null})
          console.log('TicketsToolbar updateTextFilter result', result, event.target.value)
          if (result !== 'cancel') {
            await hidePanelsLoaders()
          }
        }, 500)
      }
    }

    const changeSearchInHandler = async (searchInValue) => {
      console.log('changeSearchInHandler', searchInValue)
      if (Array.isArray(searchInValue) && searchInValue.length) {
        store.commit('ticketsfilters/setSearchIn', searchInValue)
        if (_isEqual([...searchInValue].sort(), [...searchInDefault.value].sort())) {
          removeBrowserStorageItem(getSearchInStorageKey(projectID.value), getSearchInStorageType())
        } else {
          setBrowserStorageItem(getSearchInStorageKey(projectID.value), JSON.stringify(searchInValue), getSearchInStorageType())
        }
        if (textFilter.value && await isMayContinue()) {
          showPanelsLoaders()
          await changeStateParams({searchIn: searchInValue})
          await hidePanelsLoaders()
        }
      }
    }

    // const getSearchInFromStorage = () => {
    //   let searchFieldsIds = []
    //   try {
    //     const searchFieldsStorageValue = getBrowserStorageItem(getSearchInStorageKey(), searchInStorageType)
    //
    //     console.log('TicketsToolbar searchFields getSearchFieldsIdsFromStorage', searchInStorageType, getSearchInStorageKey(), searchFieldsStorageValue)
    //
    //     if (searchFieldsStorageValue && searchFieldsStorageValue.trim()) {
    //       searchFieldsIds = JSON.parse(searchFieldsStorageValue)
    //     }
    //   } catch (e) {
    //     console.log('TicketsToolbar searchFieldsStorageValue error', e)
    //     removeBrowserStorageItem(getSearchInStorageKey(), searchInStorageType)
    //   }
    //   return Array.isArray(searchFieldsIds) ? searchFieldsIds : null
    // }

    const initSearchIn = () => {
      const searchInFromStorage = getSearchInFromStorage(projectID.value)
      console.log('TicketsToolbar initSearchIn', searchInFromStorage, projectID.value, searchFields.value)

      if (Array.isArray(searchInFromStorage) && searchInFromStorage.length) {
        store.commit('ticketsfilters/setSearchIn', searchInFromStorage)
        // if (textFilter.value && router.currentRoute.value?.query && typeof updateLocationHref === 'function') {
        //   //router.replace({query: Object.assign({}, router.currentRoute.value.query, {searchin: searchInFromStorage.join(',')})})
        //   updateLocationHref(getRouteObj({searchin: searchInFromStorage.join(',')}))
        // }
        //router.replace({query: {searchin: searchInFromStorage.join(',')}})
      } else {
        store.commit('ticketsfilters/setSearchIn', searchInDefault.value)
      }
    }

    watch([projectID, searchFields], () => {
      initSearchIn()
    }, {immediate: true})

/*
    const changeSearchFields = async ({ids}) => {
      if (ids?.length) {
        if (refSearchTextInput.value?.value && ! await isMayContinue()) {
          return
        }
        showPanelsLoaders()
        await changeStateParams({searchIn: ids.join(',')})
        await hidePanelsLoaders()
      }
      // const newSearchFields: IData[] = []
      //
      // searchFields.value.forEach(item => {
      //   const itemCopy = {...item}
      //   itemCopy.checked = ids.indexOf(item.key) != -1;
      //   newSearchFields.push(itemCopy)
      // })
      // await changeStateParams({searchFields: newSearchFields})
    }
*/

/*
    const cancelSearchFields = () => {
      // searchFieldsModel.value = [...selectedSearchFieldsNames.value]
    }
*/

    const changeCustomFields = async () =>{
      if (! await isMayContinue()) {
        return
      }
      showPanelsLoaders()
      await changeStateParams({});
      await hidePanelsLoaders()
    }

    //const selectedTags = ref<ITicketTag[]>([])
    const selectedTags = computed<ITicketTag[]>(() => store.getters['ticketsfilters/tagsFilterValues'])
    const selectedTagsIds = computed(() => selectedTags.value.map(item => item.id.toString()))
    const selectorTagsLabel = computed(() => {
      return selectedTags.value
        .map(item => item.text)
        .join(' ')
    })

    const changeRequestedByFilter = async (data) => {
      if (! await isMayContinue()) {
        return
      }
      requestedBy.value = {id: data.id, name: data.orig_text || data.text, type: data.type, avatar_data: data.data}
      const stateParams = {requestedById: data.id}
      if (data.id !== '0') {
        Object.assign(stateParams, {selectedItemId: null})
      }
      //isOpenFilterRequestedBy.value = false
      showPanelsLoaders()
      console.log('changeRequestedByFilter', stateParams, data)
      await changeStateParams(stateParams)
      await hidePanelsLoaders()
    }

    const changeAssignedToFilter = async (data) => {
      console.log('changeAssignedToFilter', data)
      if (! await isMayContinue()) {
        return
      }
      assignedTo.value = {id: data.id, name: data.orig_text || data.text, type: data.type, avatar_data: data.data}
      //isOpenFilterAssignedTo.value = false
      const stateParams = {assignedToId: data.id}
      if (data.id !== '0') {
        Object.assign(stateParams, {selectedItemId: null})
      }
      showPanelsLoaders()
      await changeStateParams(stateParams)
      await hidePanelsLoaders()
    }

    const clearTagsFilter = async () => {
      console.log('TicketsToolbar clearTagsFilter')
      if (! await isMayContinue()) {
        return
      }
      showPanelsLoaders()
      await changeStateParams({tagsFilter: [], selectedItemId: null})
      await hidePanelsLoaders()
    }

    const changeTagsFilter = async (data) => {
      console.log('TicketsToolbar changeTagsFilter', data)
      if (! await isMayContinue()) {
        return
      }
      showPanelsLoaders()
      await changeStateParams({tagsFilter: data.items, tagsLogic: data.tagsLogic, selectedItemId: null})
      await hidePanelsLoaders()
    }

    const anyUser = {id: '0', name: t('- any users -'), type: 'virtual'}
    const nobodyUser = {id: '-1', name: t('- nobody -'), type: 'virtual'}
    const requestedBy = ref<IData>(anyUser)
    const assignedTo = ref<IData>(anyUser)

    watch([users, requestedByFilter, assignedToFilter], () => {
      console.log('TicketsToolbar watch users', requestedByFilter.value, assignedToFilter.value, users.value)
      const requestedByUser = users.value.find((user) => user.key == requestedByFilter.value)
      if (requestedByUser) {
        requestedBy.value = {id: requestedByUser.key, name: requestedByUser.text, type: requestedByUser.type, avatar_data: requestedByUser.data}
      } else {
        requestedBy.value  = anyUser
      }
      const assignedToUser = users.value.find((user) => user.key == assignedToFilter.value)
      if (assignedToUser) {
        assignedTo.value = {id: assignedToUser.key, name: assignedToUser.text, type: assignedToUser.type, avatar_data: assignedToUser.data}
      } else if (assignedToFilter.value === '-1' || assignedToFilter.value === 'nobody') {
        assignedTo.value  = nobodyUser
      } else {
        assignedTo.value  = anyUser
      }
    }, {immediate: true})

    const refRequestedBy = ref()
    const refAssignedTo = ref()
    const refTagsFilter = ref()

    const refSeekerRequestedBy = ref<InstanceType<typeof SeekerUsers> | null>(null)
    const refSeekerAssignedTo = ref<InstanceType<typeof SeekerUsers> | null>(null)
    const refSeekerTags = ref<InstanceType<typeof SeekerTags> | null>(null)

    const {isExist: isExistSeekerRequestedBy, triggerHandler: triggerHandlerSeekerRequestedBy} = useFloatingExists(refSeekerRequestedBy)
    const {isExist: isExistSeekerAssignedTo, triggerHandler: triggerHandlerSeekerAssignedTo} = useFloatingExists(refSeekerAssignedTo)
    const {isExist: isExistSeekerTags, triggerHandler: triggerHandlerSeekerTags} = useFloatingExists(refSeekerTags)

    ;[refSeekerRequestedBy, refSeekerAssignedTo, refSeekerTags].forEach(async (floating) => {
      watch(() => floating.value?.isOpened, async (value) => {
        if (value) {
          await nextTick()
          floating.value?.focusFilterField()
        }
      })
    })

    const refMenuButton = ref()
    const menuButtonHandler = () => {
      emit('menuTrigger', {positionTarget: refMenuButton})
    }

    // const menuHandler = (action) => {
    //   refMenu.value.isOpened = false
    //   emit('menuAction', action)
    // }

    const addTicketClickHandler = async (event, navigate) => {
      event.preventDefault()
      if (screen.value === 'dashboard') {
        await triggerHandlerSeekerProjects()
      } else {
        navigate()
      }
    }

    const addTicketInProject = async (project) => {
      console.log('addTicketInProject', project)
      await router.push({name: 'Ticket', params: {id: 'new'}, query: {project: project.id}})
    }

    return {
      refSearchTextInput,
      refRequestedBy,
      refAssignedTo,
      refTagsFilter,

      refSeekerRequestedBy,
      refSeekerAssignedTo,
      refSeekerTags,
      refMenuButton,
      // refMenu,
      // isExistMenu,
      // triggerHandlerMenu,
      t,
      textFilter,
      searchIn,
      searchFields,
      requestedByFilter,
      assignedToFilter,
      //tagsFilterValues,
      requestedBy,
      assignedTo,
      selectorTagsLabel,
      selectedTagsIds,
      isExistSeekerRequestedBy,
      isExistSeekerAssignedTo,
      isExistSeekerTags,
      updateTextFilter,
      //changeSearchFields,
      changeSearchInHandler,
      //cancelSearchFields,
      changeRequestedByFilter,
      changeAssignedToFilter,
      changeTagsFilter,
      clearTagsFilter,
      // requestedByClickHandler,
      // assignedToClickHandler,
      // tagsClickHandler,
      //moduleName,
      searchExtraPopup,
      isExistSearchExtraPopup,
      refSearchExtraTo,
      //getSearchExtraTriggerTargets,
      //openSearchPopup,
      customFieldsPopup,
      refCustomFieldsTo,
      isExistCustomFieldsPopup,
      //openCustomFieldsPopup,
      triggerHandlerSearchExtraPopup,
      triggerHandlerSeekerRequestedBy,
      triggerHandlerSeekerAssignedTo,
      triggerHandlerSeekerTags,
      triggerHandlerCustomFieldsPopup,
      projectID,
      menuButtonHandler,
      changeCustomFields,
      //menuHandler,
      addTicketClickHandler,
      refAddTicketButton,
      refSeekerProjects,
      isExistSeekerProjects,
      addTicketInProject,
    }
  }

})

