
  import {defineComponent} from "vue"

  export default defineComponent({
    props: {
      uploaderInputId: {
        type: String,
        required: true
      }
    },
    // setup(props) {
    //
    // }
  })
