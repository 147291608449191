<template>
  <li class="menu-separator"></li>
</template>
<style lang="sass" scoped>
.menu-separator
  border-top: 1px solid var(--gray-20)
  height: 1px
  margin: 0 10px
  padding: 0
  @include dark
    border-color: var(--gray-2-93)
</style>
