import {computed, defineComponent, PropType, ref, watch} from "vue"
import {useI18n} from "vue-i18n"
import {IData, ITableField} from "@/types"

export default defineComponent({
  name: 'DataPaging',
  props: {
    pageCurrent: {
      required: false
    },
    pageLast: {
      type: Number,
      required: true
    },
  },

  setup(props, {emit}) { //props, context

    console.log('DataPaging setup', props)

    const { t } = useI18n()

    const itemsLeft = ref<number[]>([])
    const itemsMiddle = ref<number[]>([])
    const itemsRight = ref<number[]>([])

    const page = computed<number>(() => { console.log('paging page change'); return (props.pageCurrent ? parseInt(props.pageCurrent as string, 10) : 1)})

    const calculatePaging = () => {
      console.log('calculatePaging', page.value, props.pageLast)
      itemsLeft.value = []
      itemsMiddle.value = []
      itemsRight.value = []
      if (props.pageLast === 1) {
        return
      }
      if (props.pageLast < 8) {
        for (let i = 1; i <= props.pageLast; i++) {
          itemsLeft.value.push(i)
        }
      } else {
        if (page.value < 5) {
          itemsLeft.value = [1, 2, 3, 4, 5]
          itemsRight.value = [props.pageLast]
        } else if (props.pageLast - page.value < 4) {
          itemsLeft.value = [1]
          itemsRight.value = [props.pageLast - 4, props.pageLast - 3, props.pageLast - 2, props.pageLast - 1, props.pageLast]
        } else {
          itemsLeft.value = [1]
          itemsMiddle.value = [page.value - 1, page.value, page.value + 1]
          itemsRight.value = [props.pageLast]
        }
      }
    }

    calculatePaging()
    watch(() => page.value, calculatePaging);
    watch(() => props.pageLast, calculatePaging);


    //
    return {
      t,
      page,
      itemsLeft,
      itemsMiddle,
      itemsRight,
    }
  }

})
