import {IApiResponse, IData} from "@/types"
import ApiService from "@/services/api_service"
import qs from "qs"
import store from "@/store"

export default {

  toggleNotesExpanded(id: string, status: '0' | '1', cancelTokenId?: string) {
    return new Promise((resolve, reject) => {
      const actionName = 'toggle_comments_expanded'

      const params: IData = {
        action: actionName,
        id,
        status,
      }

      if (!cancelTokenId) {
        cancelTokenId = actionName + '_' + id
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('NotesService toggleNotesExpanded success', data)
        resolve(data)
      }).catch((data) => {
        console.log('NotesService toggleNotesExpanded error', data)
        reject(data)
      })
    })

  },

  loadNotes(options: IData, screenName: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionMap = {
        ticket: 'get_notes',
        dashboard: 'get_dashboard_notes',
        listview: 'get_notes',
      }
      const actionName = actionMap[screenName]

      const params: IData = {
        action: actionName,
        ...options,
      }

      if (!cancelTokenId) {
        cancelTokenId = actionName
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        const notesResponse = data?.xdata?.notes_view
        console.log('NotesService loadNotes success', data, notesResponse)
        // store.commit(screenName + '/appendNotes', notesResponse.items)
        // store.commit(screenName + '/set_notesOffset', notesResponse.data_offset)
        // store.commit(screenName + '/set_isMoreNotes', !!notesResponse.show_next)
        resolve(data)
      }).catch((data) => {
        console.log('NotesService loadNotes error', data)
        reject(data)
      })
    })
  },

  editNote(options: IData, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'edit_note'

      const params: IData = {
        action: actionName,
        ...options,
      }

      if (!cancelTokenId) {
        cancelTokenId = actionName
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('NotesService editNote success', data)
        resolve(data)
      }).catch((data) => {
        console.log('NotesService editNote error', data)
        reject(data)
      })
    })

  },

  saveNote(options: IData, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'save_note'

      const params: IData = {
        action: actionName,
        ...options,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('NotesService saveNote success', data)
        resolve(data)
      }).catch((data) => {
        console.log('NotesService saveNote error', data)
        reject(data)
      })
    })

  },

  removeNote(options: IData, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'remove_note'

      const params: IData = {
        action: actionName,
        ...options,
      }

      if (!cancelTokenId) {
        cancelTokenId = actionName + '_' + options.id
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('NotesService removeNote success', data)
        resolve(data)
      }).catch((data) => {
        console.log('NotesService removeNote error', data)
        reject(data)
      })
    })

  },

  removeAttachment(options: IData, cancelTokenId?: string, extraOptions = {}): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'remove_attachment'

      const params: IData = {
        action: actionName,
        ...options,
      }

      if (!cancelTokenId) {
        cancelTokenId = actionName + '_' + options.id
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, extraOptions).then((data) => {
        console.log('NotesService removeAttachment success', data)
        resolve(data)
      }).catch((data) => {
        console.log('NotesService removeAttachment error', data)
        reject(data)
      })
    })

  },

  getNoteText(id: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'get_note_text'

      const params: IData = {
        action: actionName,
        id,
      }
      if (!cancelTokenId) {
        cancelTokenId = actionName + '_' + id
      }
      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('NotesService getNoteText success', data)
        resolve(data)
      }).catch((data) => {
        console.log('NotesService getNoteText error', data)
        reject(data)
      })
    })

  },

}
