import {computed, onBeforeUnmount, ref, watch} from "vue"
import store from '@/store'

const keepedScroll = {}

const currentProjectId = computed(() => store.getters.project?.id)

watch(currentProjectId, () => {
  console.log('useScrollKeep watch currentProjectId', currentProjectId.value)
  delete keepedScroll['board']
  delete keepedScroll['listview']
})

export function useScrollKeep() {

  const keepScroll = (scrollContainerRef, screenName: string) => {
    if (scrollContainerRef.value && screenName) {
      keepedScroll[screenName] = scrollContainerRef.value.scrollTop
    }
    console.log('useScrollKeep keepScroll', keepedScroll[screenName], scrollContainerRef.value)
  }

  const restoreScroll = (scrollContainerRef, screenName: string) => {
    if (scrollContainerRef.value && keepedScroll[screenName]) {
      scrollContainerRef.value.scrollTop = keepedScroll[screenName]
    }
    console.log('useScrollKeep restoreScroll', keepedScroll[screenName], scrollContainerRef.value)
  }


  return {
    keepScroll,
    restoreScroll,
  }

}
