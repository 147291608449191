import {useAppUtils} from "@/composables/useAppUtils"

export function useBrowserStorage() {

  const {
    getAuthUser,
  } = useAppUtils()

  const getAppStoragePrefix = () => 'disarea.'

  const getAuthUserParamNamePart = () => {
    const authUser = getAuthUser()
    return '.u_' + authUser?.id
  }

  const _checkStorageItemNameParam = (itemName: string, useAppPrefix: boolean, forAuthUser: boolean) => {
    if (useAppPrefix) {
      itemName = getAppStoragePrefix() + itemName
    }
    if (forAuthUser) {
      itemName = itemName + getAuthUserParamNamePart()
    }
    return itemName
  }

  const getBrowserStorageItem = (itemName: string, storageType: 'localStorage' | 'sessionStorage', useAppPrefix = true, forAuthUser = true) => {
    itemName = _checkStorageItemNameParam(itemName, useAppPrefix, forAuthUser)
    if (window[storageType]) {
      try {
        return window[storageType].getItem(itemName)
      } catch (e) {
        window[storageType].clear()
      }
    }
    return null
  }

  const setBrowserStorageItem = (itemName: string, itemValue: string, storageType: 'localStorage' | 'sessionStorage', useAppPrefix = true, forAuthUser = true) => {
    itemName = _checkStorageItemNameParam(itemName, useAppPrefix, forAuthUser)
    if (window[storageType]) {
      try {
        return window[storageType].setItem(itemName, itemValue)
      } catch (e) {
        window[storageType].clear()
      }
    }
    return null
  }

  const removeBrowserStorageItem = (itemName: string, storageType: 'localStorage' | 'sessionStorage', useAppPrefix = true, forAuthUser = true) => {
    itemName = _checkStorageItemNameParam(itemName, useAppPrefix, forAuthUser)
    if (window[storageType]) {
      try {
        window[storageType].removeItem(itemName)
      }catch (e) {
        window[storageType].clear()
      }
    }
  }

  const getSearchInStorageKey = (projectID): string => 'tickets_search_in.p_' + projectID
  const getSearchInStorageType = (): 'localStorage' | 'sessionStorage' => 'localStorage'

  const getSearchInFromStorage = (projectID) => {
    //const projectID = getCurrentProjectId()
    let searchFieldsIds = []
    try {
      const searchFieldsStorageValue = getBrowserStorageItem(getSearchInStorageKey(projectID), getSearchInStorageType())

      console.log('useAppUtils searchFields getSearchFieldsIdsFromStorage', getSearchInStorageType(), getSearchInStorageKey(projectID), searchFieldsStorageValue)

      if (searchFieldsStorageValue && searchFieldsStorageValue.trim()) {
        searchFieldsIds = JSON.parse(searchFieldsStorageValue)
      }
    } catch (e) {
      console.log('useAppUtils searchFieldsStorageValue error', e)
      removeBrowserStorageItem(getSearchInStorageKey(projectID), getSearchInStorageType())
    }
    return Array.isArray(searchFieldsIds) ? searchFieldsIds : null
  }



  return {
    getBrowserStorageItem,
    setBrowserStorageItem,
    removeBrowserStorageItem,
    getSearchInStorageKey,
    getSearchInStorageType,
    getSearchInFromStorage,
  }
}
