import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-619eaff8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { href: "" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", null, [
    _createElementVNode("li", null, [
      _createElementVNode("a", _hoisted_1, [
        _createElementVNode("label", { for: _ctx.uploaderInputId }, "My Computer", 8, _hoisted_2)
      ])
    ]),
    _createElementVNode("li", null, [
      _createElementVNode("a", {
        href: "",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
      }, "Google Drive")
    ])
  ]))
}