import store from "@/store"
import {IData} from "@/types"
import ApiService from "@/services/api_service"

export default {

  getAllTags(options: IData = {}, cancelTokenId?: string): Promise<IData[]> {
    return new Promise((resolve, reject) => {

      const actionName = 'get_all_tags'
      const params: IData = {
        action: actionName,
        ...options,
      }
      if (store.getters.currentScreen !== 'dashboard') {
        params.project = store.getters.project?.id
      }
      ApiService.request({
        method: "get",
        params
      }).then((data) => {
        console.log('TagsService getAllTags success', !options.key, data)
        const tags = data?.xdata?.tags
        if (!options.key) {
          store.commit('setTags', tags)
        }
        resolve(tags)
      }).catch((data) => {
        console.log('TagsService getAllTags error', data)
        resolve([])
      })

    })
  },

  fetchEditTags: function (): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "edit_tags"
      };
      const cancelTokenId = "edit_tags"
      ApiService.request({method: 'post', params}, cancelTokenId).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  saveTag: function (id, color): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "save_tag",
        id,
        color
      };
      ApiService.request({method: 'post', params}).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  removeTag: function (id): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "del_tag",
        id
      };
      ApiService.request({method: 'post', params}).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },

}
