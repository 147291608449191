import {ref} from "vue"
import {VueUploadItem} from "vue-upload-component"
import {useAppUtils} from "@/composables/useAppUtils"
//import * as PDFJS from "pdfjs-dist/legacy/build/pdf"
//import PDFJSWorker from "pdfjs-dist/legacy/build/pdf.worker.entry"

// const PDFJS = window['pdfjsLib']
// // const PDFJSWorker = window['pdfjsWorker']
// PDFJS.GlobalWorkerOptions.workerSrc = window['pdfjsWorker'] //PDFJSWorker

let PDFJS: any = null

let loadingPdfJsLibInitiated = false
const isPdfJsLibAvailable = ref(false)

export function useFileThumbnail () {

  const mime_types_image = ['image/gif', 'image/png', 'image/jpeg', 'image/bmp', 'image/webp', 'image/svg+xml']
  const mime_types_pdf = ['application/pdf']
  const size_limit_image = 15 // Mb todo: receive from server
  const size_limit_pdf = 15

  const { loadScript, getUrlRoot } = useAppUtils()

  const loadPdfJsLib = () => {
    return new Promise((resolve, reject) => {
      if (loadingPdfJsLibInitiated && !isPdfJsLibAvailable.value) {
        console.log('loadPdfJsLib setTimeout init')
        let counter = 0
        const timer = setInterval(() => {
          console.log('loadPdfJsLib setTimeout call', isPdfJsLibAvailable.value)
          // loadPdfJsLib().then(()=>{resolve(true)}).catch()
          counter ++
          if (counter > 100) {
            clearInterval(timer)
            reject()
          }
          if (isPdfJsLibAvailable.value) {
            clearInterval(timer)
            resolve(true)
          }
        }, 300)
      } else if (isPdfJsLibAvailable.value) {
        resolve(true)
      } else {
        loadingPdfJsLibInitiated = true

        console.log('loadPdfJsLib before init', useAppUtils, loadScript, getUrlRoot)

        const urlRoot = getUrlRoot()
        console.log('loadPdfJsLib init', urlRoot + 'libs/pdfjs/pdf.min.js?v=2.16.105')
        const loadScriptPromises = [
          loadScript(urlRoot + 'libs/pdfjs/pdf.min.js?v=2.16.105'),
          loadScript(urlRoot + 'libs/pdfjs/pdf.worker.min.js?v=2.16.105'),
          // loadScript('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.min.js'),
          // loadScript('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js'),
        ]

        Promise.all(loadScriptPromises).then( data  => {
          console.log("PdfJsLib loaded successfully", data);
          if (window['pdfjsLib'] && window['pdfjsWorker']) {
            isPdfJsLibAvailable.value = true
            PDFJS = window['pdfjsLib']
            PDFJS.GlobalWorkerOptions.workerSrc = window['pdfjsWorker']
            resolve(true)
          } else {
            reject()
          }
        }).catch(() => {
          reject()
        })
      }
    })
  }

  const getFileType: (fileItem: VueUploadItem) => string = (fileItem) => {
    return fileItem.type ? fileItem.type.toLowerCase() : ''
  }
  const isImage: (fileItem: VueUploadItem) => boolean = (fileItem) => {
    return mime_types_image.indexOf(getFileType(fileItem)) !== -1
  }

  const isPdf: (fileItem: VueUploadItem) => boolean = (fileItem) => {
    return mime_types_pdf.indexOf(getFileType(fileItem)) !== -1
  }

  const isFileCanThumb: (fileItem: VueUploadItem) => boolean = (fileItem) => {
    let result = false
    const fileSizeMb = fileItem.size && fileItem.size / 1024 / 1024
    if (fileItem.file && fileSizeMb) {
      if (isImage(fileItem) && fileSizeMb <= size_limit_image) {
        result = true
      }
      if (isPdf(fileItem) && fileSizeMb <= size_limit_pdf) {
        result = true
      }
    }

    console.log('useFileThumbnail isFileCanThumb', fileSizeMb, result)
    return result
  }

  const getFileBlob = (file) => {
    const URL = (window.URL || window.webkitURL)
    return URL && URL.createObjectURL(file)
  }

  const destroyFileBlob = (objUrl) => {
    URL.revokeObjectURL(objUrl)
  }

  const _getPdfPreview = (file) => {
    return new Promise(function(resolve, reject) {
      const pdf_url = getFileBlob(file)

      const loadingTask = PDFJS.getDocument(pdf_url)
      loadingTask.promise.then(async (pdf_doc) => {

        const page = await pdf_doc.getPage(1)
        console.log('getPdfPreview page', page)

        const _CANVAS: HTMLCanvasElement = document.createElement('canvas');
        _CANVAS.width = 100;
        // set the scale of viewport
        const scale_required = _CANVAS.width / page.getViewport({scale: 1}).width;

        // get viewport of the page at required scale
        const viewport = page.getViewport({scale: scale_required});

        _CANVAS.height = viewport.height

        const canvasContext: any = _CANVAS.getContext('2d')

        const renderContext = {
          canvasContext,
          viewport
        }

        page.render(renderContext).promise.then(() => {
          //console.log('page.render toDataURL', _CANVAS.toDataURL("image/png"))
          resolve(_CANVAS.toDataURL("image/png"))
        }).finally(() => {
          console.log('page.render finally')
          destroyFileBlob(pdf_url)
        }).catch(() => {
          reject()
        })

      }).catch(() => {
        console.log('loadingTask catch')
        destroyFileBlob(pdf_url)
        reject()
      })

    })

  }

  const getPdfPreview = (file) => {
    return new Promise(function(resolve, reject) {

      loadPdfJsLib().then(() => {
        console.log('useFileThumbnail getPdfPreview loadPdfJsLib then')
        _getPdfPreview(file).then(data => {
          console.log('useFileThumbnail getPdfPreview then', data)
          resolve(data)
        }).catch((e) => {
          console.log('useFileThumbnail getPdfPreview catch', e)
          reject()
        })
      }).catch(() => {
        console.log('useFileThumbnail getPdfPreview loadPdfJsLib catch')
        reject()
      })

      //
      //
      // try {
      //   const result = await _getPdfPreview(file)
      //   if (result) {
      //     resolve(result)
      //   } else {
      //     reject()
      //   }
      //
      // } catch (e) {
      //   console.log('getPdfPreview catch')
      //   reject()
      // }
      //
      //

    })

  }

  return {
    isFileCanThumb,
    isImage,
    isPdf,
    getFileBlob,
    getPdfPreview,
    destroyFileBlob,
    isPdfJsLibAvailable,
  }

}
