import {IApiResponse, IData, ITicket} from "@/types"
import ApiService from "@/services/api_service"
import qs from "qs"
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';

export default {
  saveTicket(ticket: ITicket, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'save_ticket'
      const omitAttrs = [
        'archived',
        'insert_time',
        'update_time',
        'move_time',
        'isEdit',
      ]
      const ticketAttrs = [
        'id',
        'title',
        'flow_id',
        'flow_step_id',
        'description',
        'customer_email',
        'customer_name',
        'color',
        'deadline_date',
        'ticket_users',
        'email_notify_custom',
        'tags',
        'owner_id',
        'customer_id',
        'pos',
        //'archived', // AVB: нельзя слать этот параметр
        ]
      //const ticketParams = _pick(ticket, ticketAttrs)
      const ticketParams = _omit(ticket, omitAttrs)

      const params: IData = {
        action: actionName,
        ... ticketParams
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService saveTicket success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService saveTicket error', data)
        reject(data)
      })
    })

  },

  archiveTicket(id: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'archive_ticket'

      const params: IData = {
        action: actionName,
        id,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService archiveTicket success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService archiveTicket error', data)
        reject(data)
      })
    })

  },

  cloneTicket(ticket: ITicket, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'add_ticket'

      const params: IData = {
        action: actionName,
        clone: ticket.id,
        //...ticket,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService cloneTicket success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService cloneTicket error', data)
        reject(data)
      })
    })

  },

  removeTicket(id: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'remove_ticket'

      const params: IData = {
        action: actionName,
        id,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService removeTicket success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService removeTicket error', data)
        reject(data)
      })
    })

  },

  setCover(ticketId: string, attachmentId: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'set_ticket_cover'

      const params: IData = {
        action: actionName,
        id: ticketId,
        cover: attachmentId,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService makeCover success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService makeCover error', data)
        reject(data)
      })
    })

  },

  getNomapFields(ticketId: string, projectId: string, cancelTokenId?: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const actionName = 'get_nomap_fields'

      const params: IData = {
        action: actionName,
        ticket_id: ticketId,
        to_project_id: projectId,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId).then((data) => {
        const response = data.html
        console.log('SeekersService getSeekerProjectsItems success', data, response)
        resolve(response)
      }).catch((data) => {
        console.log('SeekersService getSeekerProjectsItems error', data)
        reject(data)
      })

    })
  },

  mergeTickets(ticketFrom: string, ticketTo: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'merge_tickets'

      const params: IData = {
        action: actionName,
        ticketFrom,
        ticketTo
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService mergeTickets success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService mergeTickets error', data)
        reject(data)
      })
    })

  },

  moveToProject(ticketId: string, projectId: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'move_ticket_to_project'

      const params: IData = {
        action: actionName,
        ticket_id: ticketId,
        flow_id: projectId,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService moveToProject success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService moveToProject error', data)
        reject(data)
      })
    })

  },

  setTicketStar(ticketId: string, star: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'set_ticket_star'

      const params: IData = {
        action: actionName,
        id: ticketId,
        star,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService setTicketStar success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService setTicketStar error', data)
        reject(data)
      })
    })
  },

  setTicketColor(ticketId: string, color: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'set_ticket_color'

      const params: IData = {
        action: actionName,
        id: ticketId,
        color,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService setTicketColor success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService setTicketColor error', data)
        reject(data)
      })
    })
  },

  moveTicketToNextStage(ticketId: string, screenName: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'move_ticket_to_next'

      const params: IData = {
        action: actionName,
        id: ticketId,
        tab: screenName,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService moveTicketToNextStage success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService moveTicketToNextStage error', data)
        reject(data)
      })
    })
  },

  assignTicketToMe(ticketId: string, screenName: string, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'assign_ticket_to_me'

      const params: IData = {
        action: actionName,
        id: ticketId,
        tab: screenName,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService setTicketStar success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService setTicketStar error', data)
        reject(data)
      })
    })
  },

  toggleTicketExpanded(ticketId: string, isExpanded: boolean, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'toggle_ticket_expanded'

      const params: IData = {
        action: actionName,
        id: ticketId,
        status: isExpanded ? 1 : 0,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('TicketService toggleTicketExpanded success', data)
        resolve(data)
      }).catch((data) => {
        console.log('TicketService toggleTicketExpanded error', data)
        reject(data)
      })
    })
  },

}
