import {computed, onBeforeMount, ref} from "vue"
import {useAppUtils} from "@/composables/useAppUtils"
import {useStore} from "vuex"

let loadingCKEditorInitiated = false
const isCKEditorAvailable = ref(false)

const richEditorsInstances = {}

window['disarea'].CKEditorTicketLinkInitialize = (editor, buttonView) => {

  const editorId = editor.id

  buttonView.set({
    // withText: true,
    // icon: "save",
    // icon: "https://csod072009s.searchunify.com/resources/Assets/upload-image.svg",
    //icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 256c0 88.4 71.6 160 160 160c28.9 0 56-7.7 79.4-21.1l-72-86.4c-11.3-13.6-9.5-33.8 4.1-45.1s33.8-9.5 45.1 4.1l70.9 85.1C371.9 325.8 384 292.3 384 256c0-88.4-71.6-160-160-160S64 167.6 64 256zM344.9 444.6C310 467 268.5 480 224 480C100.3 480 0 379.7 0 256S100.3 32 224 32s224 100.3 224 224c0 56.1-20.6 107.4-54.7 146.7l47.3 56.8c11.3 13.6 9.5 33.8-4.1 45.1s-33.8 9.5-45.1-4.1l-46.6-55.9z"/></svg>',
    icon: `<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 51.5 56.4">
  <g>
    <g id="api">
      <g id="Q-2">
        <path fill="#f09700" class="cls-1" d="M32,36.6c1-1.4,1.8-3.2,2.4-5.3.5-2.1.8-4.4.8-7.1s-.3-5.5-.9-7.8c-.6-2.2-1.4-4.1-2.4-5.6-1-1.5-2.3-2.6-3.8-3.4-1.5-.8-3.1-1.1-4.9-1.1s-3.5.4-5,1.1c-1.5.8-2.7,1.9-3.7,3.3-1,1.5-1.8,3.3-2.3,5.4-.5,2.1-.8,4.5-.8,7.3s.2,4.9.8,7.1c.5,2.1,1.3,4,2.2,5.6,1,1.5,2.2,2.8,3.7,3.6s3.2,1.3,5.1,1.3,3.5-.4,5-1.1,2.7-1.8,3.8-3.3h0ZM40,45.9c.2,0,.3-.2.5-.3,3.9-2.3,6.5-6.6,6.5-11.5V13.4c0-7.4-6-13.4-13.4-13.4H13.4C6,0,0,6,0,13.4v20.7c0,7.4,5.9,13.4,13.3,13.4h.5c5.9,0,9.3,8.9,37.8,8.9-8.2-5.4-16.7-10.5-11.5-10.5h0ZM33,51.5c-.9-.3-5.5-2.2-8.1-3.8-2.5-1.6-4.7-3.3-6.6-5.2-2.6-1-4.7-2.2-6.2-3.3-2.2-1.8-4-3.9-5.2-6.5-1.2-2.6-1.8-5.6-1.8-9.1,0-6.1,2.2-11.1,6.7-15,2.7-2.3,6-3.8,9.5-4.1,5.8-.6,10.7,1.2,14.8,5.4,1.8,1.8,3.1,3.9,4,6.2.9,2.3,1.3,4.8,1.3,7.6,0,4.5-1.3,8.5-3.8,11.9-2.5,3.4-5.8,5.7-9.8,7,1.9,3.2,3.9,5.6,6,7.1,2.2,1.5,4.6,2.4,7.4,2.6v.9c-2.5,0-7.2-1.2-8-1.5Z"/>
      </g>
    </g>
  </g>
</svg>`,
  })

  if (!richEditorsInstances[editorId]) {
    richEditorsInstances[editorId] = {
      buttonView
    }
  }

  console.log('TestTicketLink CKEditorTicketLinkInitialize', editorId, richEditorsInstances[editorId])
  if (typeof richEditorsInstances[editorId].ticketLinkButtonSetOptions === 'function') {
    richEditorsInstances[editorId].ticketLinkButtonSetOptions(buttonView)
    richEditorsInstances[editorId].buttonViewInitialized = true
  }

  buttonView.on("execute", async (e) => {
    const richEditorInstance = richEditorsInstances[editorId]
    console.log('TestTicketLink CKEditorTicketLinkInitialize execute', editorId, richEditorInstance)
    if (richEditorInstance) {
      richEditorInstance.ticketLinkButtonExecuteHandler(e)
    }
  })

}


export function useCKEditor () {

  //computed(() => !!(window['CKEDITOR'] && window['CKEDITOR']['DecoupledEditor']))

  const { loadScript, getUrlRoot, get2SymbolsLanguage } = useAppUtils()
  const store = useStore()

  const editorLanguage = get2SymbolsLanguage()

  onBeforeMount(() => {
    if (!isCKEditorAvailable.value && !loadingCKEditorInitiated) {
      loadingCKEditorInitiated = true


      const editorLang = editorLanguage.value
      console.log('useCKEditor siteLang', editorLang)

      // https://cdn.ckeditor.com/ckeditor5/35.1.0/super-build/translations/ru.js

      const urlRoot = getUrlRoot()
      const fileVersionForAntiCahce = '20241010'
      const loadScriptPromises = [
        //loadScript('https://cdn.ckeditor.com/ckeditor5/35.1.0/super-build/ckeditor.js')
        loadScript(urlRoot + `libs/ckeditor/ckeditor.js?v=${fileVersionForAntiCahce}`)
      ]
      if (editorLang !== 'en') {
        //loadScriptPromises.push(loadScript(`https://cdn.ckeditor.com/ckeditor5/35.1.0/super-build/translations/${editorLang}.js`))
        loadScriptPromises.push(loadScript(urlRoot + `libs/ckeditor/translations/${editorLang}.js?v=${fileVersionForAntiCahce}`))
      }

      Promise.all(loadScriptPromises).then( data  => {
        console.log("Script loaded successfully", data);
        isCKEditorAvailable.value = true
      }).catch(reason => {
        console.log('Loading CKEditor error', reason)
      })

      // const scriptElement = document.createElement('script')
      // scriptElement.src = 'https://cdn.ckeditor.com/ckeditor5/35.1.0/super-build/ckeditor.js'
      // scriptElement.onload = () => { isCKEditorAvailable.value = true }
      // document.head.appendChild(scriptElement)
    }
  })

  const registerRichEditorInstance = (editorId, instance) => {
    console.log('TestTicketLink registerRichEditorInstance', editorId, instance)
    Object.assign(richEditorsInstances[editorId], instance)

    if (!richEditorsInstances[editorId].buttonViewInitialized && richEditorsInstances[editorId].buttonView && typeof richEditorsInstances[editorId].ticketLinkButtonSetOptions === 'function') {
      richEditorsInstances[editorId].ticketLinkButtonSetOptions(richEditorsInstances[editorId].buttonView)
      richEditorsInstances[editorId].buttonViewInitialized = true
    }

  }

  const unregisterRichEditorInstance = (editorId) => {
    console.log('TestTicketLink unregisterRichEditorInstance', editorId)
    delete richEditorsInstances[editorId]
  }


  return {
    isCKEditorAvailable,
    editorLanguage,
    //getEditorLanguage,
    registerRichEditorInstance,
    unregisterRichEditorInstance,
  }

}
