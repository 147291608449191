import {computed, defineComponent, onMounted, onUnmounted, PropType, ref, watch} from "vue"
import {useI18n} from "vue-i18n"
import FileUpload, {VueUploadItem} from "vue-upload-component"
import {IData} from "@/types"
import {useFileThumbnail} from "@/composables/useFileThumbnail"
import {useStore} from "vuex"
import {useAppUtils} from "@/composables/useAppUtils"
import {useLocale} from "@/composables/useLocale"

export default defineComponent({
  //name: 'NoteUploader',
  props: {
    inputElementId: {
      type: String,
      required: true
    },
    requestData: {
      type: Object as PropType<IData>,
      default: () => ({})
    },
    uploadAction: {
      type: String,
      required: true
    },
    modelValue: {
      type: Array as PropType<VueUploadItem[]>,
      default: () => ([])
    },
    drop: {
      type: HTMLElement,
      required: false
    },
    activeAttachmentsCount: {
      type: Number,
      default: 0
    },
    countLimit: {
      type: Number,
      default: 20
    },
    sizeLimit: {
      type: Number,
      default: 78643200
    },
  },
  emits: [
    'update:modelValue',
    'filesCountLimitReached'
  ],

  components: {
    FileUpload,
  },

  setup(props, {emit}) { //props, context

    console.log('NoteUploader setup', props)

    const { t } = useI18n()
    const { f } = useLocale()
    const store = useStore()

    const refFileUpload = ref<InstanceType<typeof FileUpload> | null>(null)
    const dropActive = computed(() => refFileUpload.value && refFileUpload.value.dropActive)
    const files = ref<VueUploadItem[]>(props.modelValue)
    const countLimitReached = ref(0)

    const { getUrlRoot } = useAppUtils()
    const urlRoot = getUrlRoot()
    const emptyImageSrc = {
      x1: urlRoot + 'img/no_preview_v2.png',
      x2: urlRoot + 'img/no_preview_v2x2.png'
    }

    const {
      isFileCanThumb,
      isImage,
      isPdf,
      getFileBlob,
      getPdfPreview,
      destroyFileBlob,
    } = useFileThumbnail()

    watch(() => props.modelValue, () => {
      //console.log('NoteUploader watch props.modelValue', props.modelValue, files.value)
      files.value = props.modelValue
    })

    watch(files, () => {
      console.log('NoteUploader watch files', files.value.length, files.value)
      emit('update:modelValue', files.value)
    })

    watch(countLimitReached, () => {
      //console.log('countLimitReached watch', countLimitReached.value)
      if (countLimitReached.value > 0) {
        emit('filesCountLimitReached')
      }
    })

/*

    onMounted(() => {
      console.log('addEventListener paste')
      document.addEventListener('paste', pasteHandler);
    });

    onUnmounted(() => {
      console.log('removeEventListener paste')
      document.removeEventListener('paste', pasteHandler)
    });


    const pasteHandler = (event) => {
      console.log('pasteHandler', event, refFileUpload.value, props.requestData)
      if (refFileUpload.value && event.clipboardData) {
        refFileUpload.value.addDataTransfer(event.clipboardData)
      }
    }

*/

    const alertFileSizeLimit = async (fileItem) => {
      //alert(`File ${fileItem.name} is too big (limit: ${props.sizeLimit})`)
      await store.dispatch('addNotification', {
        //text: `File ${fileItem.name} is too big (limit: ${Math.round(props.sizeLimit / 1024 / 1024)}Mb)`,
        text: f('File "%s" is too large', `<b>${fileItem.name}</b>`),
        type: 'warning',
      })
    }

    //let inputFilterCounter = 0

    const input = (event) => {
      console.log('NoteUploader input', event.target.files)
      // inputFilterCounter = 0
      // countLimitReached.value = false
      resetUploader()
    }

    // const beforeAddingFiles = () => {
    //   inputFilterCounter = 0
    //   countLimitReached.value = 0
    // }

    const uploadAttempts = {
      total: 0,
      processed: 0,
      // success: 0,
      // fail: 0,
    }

    const resetUploader = () => {
      uploadAttempts.total = 0
      uploadAttempts.processed = 0
    }

    const inputFilter = async (newFile: VueUploadItem | undefined, oldFile: VueUploadItem | undefined, prevent: (prevent?: boolean) => boolean) => {
      //console.log('NoteUploader inputFilter', newFile, oldFile, 'files count:', files.value.length, newFile?.name, newFile?.size)

      if (newFile && !oldFile) {
        // Before adding a file
        uploadAttempts.total ++


        // if (props.activeAttachmentsCount + inputFilterCounter >= props.countLimit - 1) {
        //   countLimitReached.value ++
        // }

        console.log('NoteUploader uploadAttempts inputFilter', props.activeAttachmentsCount + uploadAttempts.total - uploadAttempts.processed > props.countLimit, props.activeAttachmentsCount, props.countLimit, JSON.stringify(uploadAttempts))
        //if (props.activeAttachmentsCount + inputFilterCounter >= props.countLimit) {
        if (props.activeAttachmentsCount + uploadAttempts.total - uploadAttempts.processed > props.countLimit) {
          uploadAttempts.processed ++
          countLimitReached.value ++
          return prevent()
        }

        if (newFile.size && newFile.size > props.sizeLimit) {
          uploadAttempts.processed ++
          alertFileSizeLimit(newFile)
          return prevent()
        }

        //uploadAttempts.processed ++
        //inputFilterCounter++

        /*
                // Filter system files or hide files
                if (newFile.name && /(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                  return prevent()
                }

                // Filter php html js file
                if (newFile.name && /\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                  return prevent()
                }
        */
      }

      if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {

        console.log('inputFilter getPdfPreview isFileCanThumb', isFileCanThumb(newFile), isImage(newFile), isPdf(newFile))
        if (isFileCanThumb(newFile)) {
          if (isImage(newFile)) {
            const fileBlob = getFileBlob(newFile.file)
            //console.log('inputFilter fileBlob', fileBlob)
            if (fileBlob) {
              newFile.preview_url = fileBlob
              newFile.canThumb = true
              delete newFile.srcset
            }
          } else if (isPdf(newFile)) {
            console.log('before getPdfPreview', newFile.preview_url)
            try {
              const previewUrl = await getPdfPreview(newFile.file)
              console.log('after getPdfPreview previewUrl', previewUrl)
              if (previewUrl) {
                const fileItem = files.value.find(item => item.id === newFile.id)
                console.log('after getPdfPreview fileItem', fileItem, fileItem?.active, fileItem?.download_url)
                if (fileItem && !fileItem.download_url) {
                  fileItem.preview_url = previewUrl
                  fileItem.canThumb = true
                  delete fileItem.srcset
                  console.log('after getPdfPreview', fileItem.preview_url)
                }
              }
            } catch (e) {
              console.log('getPdfPreview catch', e)
            }
          }
        }

        /*
                // Create a blob field
                newFile.blob = ''
                const URL = (window.URL || window.webkitURL)
                if (URL) {
                  newFile.blob = URL.createObjectURL(newFile.file)
                }
                // Thumbnails
                // newFile.thumb = ''
                if (newFile.blob && newFile.type?.substr(0, 6) === 'image/') {
                  newFile.preview_url = newFile.blob
                  const testEl = document.getElementById('testThumbImg')
                  if (testEl) {
                    testEl.setAttribute('src', newFile.blob)
                  }

                }
        */
      }

/*
      if (!newFile && oldFile) {
        // Remove file

        // alert('Filter remove file ' + oldFile.name)

        // Refused to remove the file
        // return prevent()
      }
*/

    }

    // add, update, remove File Event
    const inputFile = (newFile: VueUploadItem | undefined, oldFile: VueUploadItem | undefined) => {
      console.log('NoteUploader inputFile', newFile, oldFile)
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          console.log('inputFile beforeSend')
          // min size
          // if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
          //   this.$refs.upload.update(newFile, { error: 'size' })
          // }
        }
        // if (newFile.progress !== oldFile.progress) {
        //   // progress
        //   //console.log('inputFile progress')
        // }
        if (newFile.error && !oldFile.error) {
          // error
          //uploadAttempts.success --
          //uploadAttempts.fail ++
          //uploadAttempts.processed ++
          console.log('NoteUploader inputFile error', JSON.stringify(uploadAttempts))
        }
        if (newFile.success && !oldFile.success) {
          // success
          //uploadAttempts.processed ++
          console.log('NoteUploader inputFile success', JSON.stringify(uploadAttempts))
          if (newFile.response) {
            const fileItem = files.value.find(item => item.id === newFile.id)
            if (newFile.response.code === 0) {
              if (fileItem) {
                fileUploaded(fileItem, newFile)
              }
            } else {
              if (fileItem) {
                fileItem.canThumb = false
                fileItem.error = newFile.response.message
              }
            }
            store.commit('setStorageLimitReached', !!newFile.response.storageLimitReached)
          }
        }
      }
      if (!newFile && oldFile) {
        // remove
        //uploadAttempts.success --
        //uploadAttempts.fail ++
        //uploadAttempts.processed ++
        console.log('NoteUploader inputFile remove', JSON.stringify(uploadAttempts))
        if (oldFile.success && oldFile?.response?.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (newFile && !oldFile) {

        // if (this.uploadAuto && !this.$refs.upload.active) {
        //   this.$refs.upload.active = true
        // }

        if (newFile) {
          console.log('inputFile fileAdded')
          fileAdded(newFile)
          //newFile.active = true
        }



      }
    }

    const fileAdded = (fileItem: VueUploadItem) => {
      //isMinimized.value = false

      if (refFileUpload.value) {
        refFileUpload.value.active = true
      }


      //fileItem.active = true
      uploadAttempts.processed ++
      console.log('NoteUploader uploadAttempts fileAdded', JSON.stringify(uploadAttempts))

      //const fileParams: IData = getNewFileParams(fileItem)
      if (isFileCanThumb(fileItem)) {
        if (!fileItem.preview_url) {
          console.log('fileAdded', fileItem, fileItem.preview_url)
          fileItem.preview_url = emptyImageSrc.x1
          fileItem.srcset = emptyImageSrc.x1 + ', ' + emptyImageSrc.x2 + ' 2x'
        }
        fileItem.canThumb = true
        //uploadingFilesWithThumb.value.unshift(fileParams)
      }
      // else {
      //   uploadingFilesWithoutThumb.value.unshift(fileParams)
      // }
    }

    const fileUploaded = (fileItem: VueUploadItem, newFile: VueUploadItem) => {
      console.log('fileUploaded', fileItem, newFile)
      fileItem.canThumb = newFile.response?.canThumb
      fileItem.previewInPopup = newFile.response?.previewInPopup
      fileItem.download_url = newFile.response?.download_url
      fileItem.obj_type = newFile.response?.obj_type
      fileItem.ext = newFile.response?.ext

      if (fileItem.preview_url !== emptyImageSrc.x1) { // значит preview_url - это полученный в js thumbnail
        destroyFileBlob(fileItem.preview_url)
      }
      if (newFile?.response?.preview_url) {
        fileItem.preview_url = newFile.response.preview_url
        // fileItem.uid = fileItem.id
        // fileItem.id = newFile.response.id
        //fileItem.uid = newFile.response.id
      } else {
        delete fileItem.preview_url
      }
      if (newFile?.response?.srcset) {
        fileItem.srcset = newFile.response.srcset
      } else {
        delete fileItem.srcset
      }

    }

    const removeFileItem = (fileItem) => {
      if (fileItem && refFileUpload.value) {
        console.log('NoteUploader removeFileItem', fileItem.id, JSON.stringify(files.value))
        refFileUpload.value.remove(fileItem)
        //uploadAttempts.processed ++
        console.log('NoteUploader removeFileItem', JSON.stringify(files.value), JSON.stringify(uploadAttempts))
      }
    }

    const getFileItemById: (id: string) => VueUploadItem | undefined = (id) => files.value.find(item => item.id === id)

    const uploadActive = computed(() => !!refFileUpload.value && !refFileUpload.value.uploaded)

    return {
      t,

      files,
      refFileUpload,
      dropActive,
      uploadActive,

      //beforeAddingFiles,
      resetUploader,

      input,
      inputFile,
      inputFilter,
      removeFileItem,
      getFileItemById,
    }
  }

})
