import {onMounted, onUnmounted} from "vue"

const noteForm = {
  top: '',
  bottom: '',
}

let lastActiveForm = ''

export function usePasteUpload (refNoteUploader, formUid, formType) {

  onMounted(() => {
    console.log('usePasteUpload addEventListener paste', refNoteUploader.value.requestData.form_id)
    document.addEventListener('paste', pasteHandler)
    noteForm[formType] = formUid.value
    lastActiveForm = formUid.value
  })

  onUnmounted(() => {
    console.log('usePasteUpload removeEventListener paste', refNoteUploader.value)
    document.removeEventListener('paste', pasteHandler)
    noteForm[formType] = ''
    if (lastActiveForm === formUid.value) {
      lastActiveForm = ''
    }
  })

  const markActiveForPasteUpload = uid => {
    lastActiveForm = uid
  }
  const markInactiveForPasteUpload = uid => {
    if (lastActiveForm === uid) {
      lastActiveForm = ''
    }
  }

  const isActiveForUpload = () => {
    console.log('usePasteUpload isActiveForUpload', formUid.value, lastActiveForm, noteForm)
    let result = false
    if (lastActiveForm) {
      result = formUid.value === lastActiveForm
    } else if (noteForm.bottom) {
      result = formUid.value === noteForm.bottom
    } else if (noteForm.top) {
      result = formUid.value === noteForm.top
    }
    return result
  }

  const pasteHandler = (event) => {
    const refFileUpload = refNoteUploader.value?.refFileUpload
    console.log('usePasteUpload pasteHandler', event, refNoteUploader.value.requestData.form_id, refNoteUploader, refFileUpload)
    if (refFileUpload && event.clipboardData && isActiveForUpload()) {
      //refNoteUploader.value.beforeAddingFiles()
      if (typeof refNoteUploader.value?.resetUploader === "function") {
        refNoteUploader.value.resetUploader()
      }
      refFileUpload.addDataTransfer(event.clipboardData)
    }
  }

  return {
    isActiveForUpload,
    pasteHandler,
    markActiveForPasteUpload,
    markInactiveForPasteUpload,
  }

}
