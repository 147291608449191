import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_upload = _resolveComponent("file-upload")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_file_upload, {
      style: {"display":"none"},
      ref: "refFileUpload",
      "input-id": _ctx.inputElementId,
      multiple: true,
      drop: _ctx.drop,
      "drop-directory": true,
      "post-action": _ctx.uploadAction,
      data: _ctx.requestData,
      "add-index": false,
      thread: 1,
      modelValue: _ctx.files,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.files) = $event)),
      onInput: _ctx.input,
      onInputFile: _ctx.inputFile,
      onInputFilter: _ctx.inputFilter
    }, null, 8, ["input-id", "drop", "post-action", "data", "modelValue", "onInput", "onInputFile", "onInputFilter"])
  ]))
}