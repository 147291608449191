import { Fancybox } from "@fancyapps/ui"
import "@fancyapps/ui/dist/fancybox.css"
import {IData} from "@/types"

export function useFancybox () {
  const openFancybox = (event, curItemId, imageList) => {
    console.log(' --------------------- openFancybox -----------------------', event.preventDefault, curItemId, imageList)

    const gallery: IData[] = []
    let startIndex = 0

    event.preventDefault()

    imageList.forEach(item => {
      if (item.id === curItemId) {
        startIndex = gallery.length
      }
      if (item.previewInPopup && item.download_url) {
        gallery.push({
          src: item.download_url,
          thumb: item.preview_url,
          caption: item.name,
        })
      }
    })
    console.log('openFancybox', event, imageList, gallery)
    Fancybox.show(gallery, {
      startIndex,
      Toolbar: {
        display: [
          // { id: "prev", position: "center" },
          // { id: "counter", position: "center" },
          // { id: "next", position: "center" },
          "counter",
          "zoom",
          //"slideshow",
          "fullscreen",
          "download",
          "thumbs",
          "close",
        ],
      },
    })
  }

  const showProjectTemplatePreview = (img, img2x) => {
    const imgAttrs = {
      src: img,
    }
    if (img2x) {
      Object.assign(imgAttrs, {
        srcset: img + ', ' + img2x + ' 2x',
      })
    }
    Fancybox.show([imgAttrs])
  }

  return {
    openFancybox,
    showProjectTemplatePreview,
  }
}
